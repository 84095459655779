<template>
  <svg
    width="36"
    height="26"
    viewBox="0 0 36 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="w-8 h-6"
  >
    <path
      d="M34.5 10C33.6667 13.5 31.9 20.9 31.5 22.5C31.1 24.1 29.6667 24.5 29 24.5H6.5C5.83333 24.5 4.4 24.1 4 22.5C3.6 20.9 1.83333 13.5 1 10M30 12.5H5M28.5 18.5H7"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M24.5 8C24.5 4.13401 21.366 1 17.5 1C13.634 1 10.5 4.13401 10.5 8"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>
